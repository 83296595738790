export default defineI18nConfig(() => ({
    legacy: false,
    defaultLocale: 'en',
    locales: ['en', 'da'],
    // messages: {
    //     en: {
    //         welcome: 'Welcome'
    //     },
    //     fr: {
    //         welcome: 'Bienvenue'
    //     }
    // }
}))


// export default defineI18nConfig(nuxt => ({
//     locales: ['en', 'da'], // used in URL path prefix
//     defaultLocale: 'en',
//     strategy: 'prefix_except_default',
//     legacy: false,
//     locale: 'en',
    
// }))

